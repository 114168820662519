<template>
  <div class="about">
    <section>
      <content-title name="Sobre mi" />
      <bar />
      <p>
        Andrea Thomé con 30 años de experincia en el mundo del Interiorismo. Ha trabajado en Artidea, desde el año 1991, en Uruguay.
        Esta especialidad en Feng Shui y espacios funcionales.
        Su próximo reto es expandir su conocimiento en interirorismo al resto del mundo a partir de tres obras que lanzará este 2023.
      </p>
    </section>
    <section class="what-i-am-doing">
      <content-title name="Servicios" />
      <div class="info-boxes">
        <info-box
          icon="el-icon-edit"
          title="Proyectos a medida"
          description="Realizamos proyectos a medida desde obras nuevas integrales hasta ambientes especificos."
        />
        <info-box
          icon="el-icon-edit"
          title="Telas"
          description="Trabajamos con las mejores marcas de telas. Destacando el diferencial en sus diseños exclusivos."
        />
        <info-box
          icon="el-icon-edit"
          title="Muebles"
          description="Diseñamos y organizamos los muebles en sus respectivos espacios"
        />
        <info-box
          icon="el-icon-edit"
          title="Alfombras"
          description="Manejamos todo tipo de calidades y diseños exclusivos en nuestras alfombras"
        />
      </div>
    </section>
    <section>
      <footer-content />
    </section>
  </div>
</template>

<script>
import ContentTitle from "@/components/contents/Title";
import Bar from "@/components/contents/Bar.vue";
import InfoBox from "@/components/infoBox/InfoBox.vue";
import FooterContent from "@/components/contents/Footer.vue";

export default {
  name: "About",
  components: {
    ContentTitle,
    Bar,
    InfoBox,
    FooterContent,
  },
};
</script>

<style lang="scss" scoped>
.about {
  section {
    padding: 0 4%;
  }
  .what-i-am-doing {
    background-color: #f5f8f9;
    padding: 3% 4%;

    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 1.5rem;
    }

    .info-boxes {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
</style>
