<template>
  <div class="info-box">
    <div class="icon">
      <i :class="icon"></i>
    </div>
    <div>
      <h3 class="title">{{ title }}</h3>
      <p class="description">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  margin: 10px;
  width: 300px;
  height: 130px;
}
.icon {
  font-size: 24px;
  margin-right: 30px;
}
.title {
  font-weight: 700;
  line-height: 1.3;
  margin-top: 8px;
}
.description {
  margin-bottom: 0;
}
</style>
